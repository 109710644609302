import type { GetAuthConfigResponse, GetUserResponse } from '@shared/services/customer.models';
import type { GranularRole } from '@shared/state/auth.store';

import { core } from '@shared/services/api';

export function getUser(emailAddress: string) {
  return core.get<GetUserResponse>('/v1/users/details', {}, { 'X-User-Email': emailAddress });
}

export function getUsers() {
  return core.get<GetUserResponse[]>('/v1/users');
}

export function getAuthConfig() {
  return core.get<GetAuthConfigResponse>(`/v1/auth_config`);
}

export function addUser(firstName: string, lastName: string, emailAddress: string, userRole: string) {
  return core.post('/v1/users', {
    first_name: firstName,
    last_name: lastName,
    email_address: emailAddress,
    user_role: userRole,
  });
}

export function addGranularUser(
  firstName: string,
  lastName: string,
  emailAddress: string,
  granularRoles: GranularRole[]
) {
  return core.post('/v1/users', {
    first_name: firstName,
    last_name: lastName,
    email_address: emailAddress,
    granular_roles: granularRoles.map(r => r.toRoleString()),
  });
}

export function updateGranularRoles(emailAddress: string, granularRoles: GranularRole[]) {
  return core.post('/v1/users/update_role', {
    granular_roles: granularRoles.map(r => r.toRoleString()),
  }, {}, { 'X-User-Email': emailAddress });
}

export function updateUserRole(emailAddress: string, userRole: string) {
  return core.post('/v1/users/update_role', {
    user_role: userRole,
  }, {}, { 'X-User-Email': emailAddress });
}

export function createSession() {
  return core.post(`/v1/session`);
}

export function getHubSpotToken() {
  const url = `/v1/users/hubspot_token`;
  return core.post<{ token: string }>(url, {});
}

export function resendVerification(emailAddress: string) {
  return core.post('/v1/users/resend_verification', null, {}, { 'X-User-Email': emailAddress });
}

export function deleteSession() {
  return core.delete(`/v1/session`);
}

export function deleteUser(emailAddress: string) {
  return core.delete('/v1/users', {}, { 'X-User-Email': emailAddress });
}
